<template>
  <div class="mb-5">
    明日の起床時に口すすぎ液と唾液を採取して頂きます。以下のアンケートにお答え下さい。
    <br>
    なお、謝礼は、すべてに正しく答えて頂き、2種の試料受領確認後、ご自宅に発送されます。
  </div>
  <button type="button" class="btn_next btn btn-primary" @click="onClickButton">アンケートに答える</button>
</template>
<script>
import { Mixin } from '@/mixin.js'
import { getMemberCode } from '@/utils/common.js';
import { getActivateFlg } from '@/api/member.js';
import { CONST } from '@/constants.js';

export default {
  mixins: [Mixin],
  async created() {
    const memberCode = getMemberCode()
    const activate_flg = await getActivateFlg(memberCode)

    switch(activate_flg.value) {
      case CONST.MEMBER.ACTIVATE_FLG.RESEARCH_SUBJECT:
        this.$router.push({name: 'RegisterResearch'})
        break
      case CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_AGREE:
        break
      case CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_COMPLETED:
        this.$router.push({name: 'ProductionComplete'})
        break
      default:
        this.$router.push({name: 'LoginPage'})
        break
    }
  },
  methods: {
    onClickButton() {
      this.$router.push('/survey/answer/8')
    },
  },
}
</script>

<style src="../assets/css/pages/questionnaire.css" scoped></style>
